<template lang="pug">
.not-found.flex.flex--fdc.flex--jcc.flex--aic.ta-c
	h1 404
	p Страница не найдена
</template>

<script>
export default {
	name: 'NotFound'
}
</script>

<style lang="scss">
.not-found {
	min-height: calc(100vh - 120px);
	font-size: 60px;
	line-height: 100px;
}
</style>
